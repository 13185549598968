import { useEffect, useState } from "react"

const getDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width, height
    }
};

const useDimension = () => {

    const [dimensions, setDimensions] = useState(getDimensions())
    
    useEffect(() => {
        
        function handleResize() {
            setDimensions(getDimensions())
        }

        window.addEventListener('resize', handleResize)
        return window.removeEventListener('resize', handleResize)
    
    }, [])

    return dimensions;
};

export { useDimension };