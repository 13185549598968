import { useEffect, useState } from "react"
import { firestore, storage } from "../firebase";

const usePrices = () => {
    const [prices, setPrices] = useState([]);

    useEffect(() => {
        const pricesPhotosRef = storage.ref('prices');
        const listener = firestore.collection('prices').onSnapshot(async listRef => {
            const _prices = [];
            for (const price of listRef.docs) {
                let _price = { ...price.data(), id: price.id };
                let photoUrl = null;
                if (_price.photo) {
                    try {
                        photoUrl = await pricesPhotosRef.child(_price.photo).getDownloadURL();
                    } catch { /* image not found */ }
                }
                _prices.push({ ..._price, photoUrl });
            }
            setPrices(_prices);
        });
        return listener;
    }, []);

    return [prices];
};

export { usePrices };