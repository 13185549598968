import { SectionTitle } from "../atoms";
import { useReviews } from "../hooks";
import { Card, Box, Typography, Tooltip, Fab, TextField, Button, Dialog, DialogTitle, DialogActions } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import { useState } from "react";

const styles = (theme) => ({
  small: {
    display: "flex",
    width: theme.spacing(4),
    height: theme.spacing(4),
    minHeight: "initial",
    justifyContent: "center",
    alignItems: "center"
  }
});

const _Testimonials = ({ roles, classes, user, userData }) => {

  const { reviews, handleSave, newReview, setNewReview, handleRemove } = useReviews(userData);
  const [deleteReviewDialog, setDeleteReviewDialog] = useState(false);

  return (
    <div id='testimonials'>
      <div className='container'>
        <SectionTitle title="Recensioni" description="" />
        <div className='row justify-content-center'>
          {reviews.map(review => <Card key={review.id} style={{ marginBottom: 10, marginTop: 10, marginLeft: "auto", marginRight: "auto", padding: 10, display: 'flex', width: "90%", flexDirection: "column" }}>
            <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Typography variant="h6">
                {review.author}
              </Typography>
              {roles.includes("admin")
                ? <Tooltip title="Rimuovi">
                  <Fab
                    className={"button-bg"}
                    classes={{ sizeSmall: classes.small }}
                    color="secondary"
                    size="small"
                    onClick={() => setDeleteReviewDialog(review.id)}
                  >
                    <i className='fa fa-times' style={{ fontSize: 14, margin: "auto" }}></i>
                  </Fab>
                </Tooltip>
                : null
              }
            </Box>
            <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Rating value={review.rating} readOnly />
            </Box>
            <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Typography style={{ whiteSpace: "pre-wrap" }} >
                {review.comment}
              </Typography>
            </Box>
          </Card>
          )}
          <Card style={{ marginBottom: 10, marginTop: 10, marginLeft: "auto", marginRight: "auto", width: "90%", padding: 10, display: 'flex', flexDirection: "column" }}>
            <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Typography variant="h6">
                {newReview.author}
              </Typography>
            </Box>
            <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Rating
                name="newRating"
                size="large"
                onChange={(event, newValue) => {
                  setNewReview({ ...newReview, rating: newValue });
                }}
                value={newReview.rating} />
            </Box>
            <Box mt={1.5} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <TextField
                multiline
                variant="outlined"
                style={{ flex: 1 }}
                value={newReview.comment}
                label="Dicci cosa ne pensi"
                onChange={(e) => setNewReview({ ...newReview, comment: e.target.value })}
              />
            </Box>
            <Box mt={1.5} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                onClick={handleSave}
                className={"button-bg"}
                color="primary">
                Invia
                    </Button>
            </Box>
          </Card>
          <Dialog open={Boolean(deleteReviewDialog)}>
            <DialogTitle>Sicuro di voler eliminare la recensione ?</DialogTitle>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => setDeleteReviewDialog(false)}
                // className={"button-bg"}
                color="primary">
                Annulla
                </Button>
              <Button
                variant="contained"
                onClick={() => {
                  handleRemove(deleteReviewDialog, roles);
                  setDeleteReviewDialog(false);
                }}
                className={"button-bg"}
                color="primary">
                Elimina
                </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  )
}

export const Testimonials = withStyles(styles)(_Testimonials);