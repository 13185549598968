import { useEffect, useState } from "react";
import { firestore, storage } from "../firebase";

const useCollaborators = () => {

    const [collaborators, setCollaborators] = useState([]);

    useEffect(() => {
        const collaborators_ref = storage.ref('collaborators')
        var _collaborators = [];
        firestore.collection('collaborators').get().then(async collaborators_collection => {
            for (let collaborator of collaborators_collection.docs) {
                const _collaborator = { ...collaborator.data(), id: collaborator.id };
                if (_collaborator.photo) {
                    const photoUrl = await collaborators_ref.child(`${collaborator.data().photo}`).getDownloadURL();
                    _collaborators.push({ ..._collaborator, photoUrl });
                } else {
                    _collaborators.push({ ..._collaborator });
                }
            };
            setCollaborators(_collaborators);
        });
    }, []);

    return [collaborators];
}
export { useCollaborators };