import { useCollaborators } from "../hooks";
import { Avatar } from "@material-ui/core";
import { SectionTitle } from "../atoms";

export const Team = () => {

  const [collaborators] = useCollaborators();

  return (
    <div className='container'>
      <div id='team' className='text-center'>
      <SectionTitle title="Chi Siamo" description="" />
        {/* <div className='section-title'>
          <h2>Chi siamo</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p>
        </div> */}
        <div className="row">
          {collaborators
            ? collaborators.map((collaborator, i) => (
              <div key={`${collaborator.name}-${i}`} className='col-md-3 col-sm-6'>
                <div className='thumbnail'>
                  {' '}
                  <Avatar src={collaborator.photoUrl} alt='...' className='team-img' />
                  <div className='caption'>
                    <h4>{collaborator.name}</h4>
                    <p>{collaborator.description}</p>
                  </div>
                </div>
              </div>
            ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
