const SectionTitle = ({ title, description }) => {

    return (
        <div className='section-title text-center' style={{whiteSpace: "pre-wrap"}}>
            <h2>{title}</h2>
            { description && <p>
                    {description}
                </p>
            }
        </div>
    )

};
export { SectionTitle };