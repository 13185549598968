import { useEffect, useState } from "react"
import { firestore, storage } from "../firebase";

const useProducts = () => {
    
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const productPhotosRef = storage.ref('products');
        const listener = firestore.collection('products').onSnapshot(async listRef => {
            const _products = [];
            for (const product of listRef.docs) {
                let _product = { ...product.data(), id: product.id };
                let photoUrl = null;
                if (_product.photo) {
                    try {
                        photoUrl = await productPhotosRef.child(_product.photo).getDownloadURL();
                    } catch { /* image not found */ }
                }
                _products.push({ ..._product, photoUrl });
            }
            setProducts(_products);
        });
        return listener;
    }, []);

    return [products];
};

export { useProducts };