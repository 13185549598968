import { useEffect, useState } from 'react';
import { firestore, auth } from "../firebase";
import authentication from '../services/authentication';

const useUser = () => {

    const [userInfo, setUserInfo] = useState({});
    
    const { user, userData } = userInfo;

    useEffect(() => {
        const listener = auth.onAuthStateChanged(_user => {
            if (_user) {
                setUserInfo({...userInfo, user: _user});
                return
            }
            setUserInfo({});
        });
        return listener;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        if(!user) {
            return;
        }
        const listener = firestore.collection("users").doc(user.uid).onSnapshot(_userInfo => {
            
            authentication.getRoles().then(roles => {
                
                setUserInfo({ user, userData: {..._userInfo.data(), roles: roles || []} });
                return;
            })
        });
        return listener;
    }, [user]);
    
    return { user, userData };
};

export { useUser };