import React, { useState } from 'react';
import { Navigation } from './components/navigation';
import { Header } from './components/header';
// import { Features } from './components/features';
// import { About } from './components/about';
// import { Services } from './components/services';
import readingTime from 'reading-time';
import { Gallery } from './components/gallery';
import { Testimonials } from './components/testimonials';
import { Team } from './components/Team';
import { Contact } from './components/contact';
// import JsonData from './data/data.json';
// import SmoothScroll from 'smooth-scroll';
import { Prices } from './components/prices';
import authentication from './services/authentication';
import { Snackbar, Button, MuiThemeProvider, unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import SignInDialog from './atoms/dialogs/SignInDialog';
import { useDimension, useUser } from './hooks';
import AlertDialog from './atoms/dialogs/AlertDialog';
import SignUpDialog from './atoms/dialogs/SignUpDialog';
import SettingsDialog from './atoms/dialogs/SettingsDialog';
import DeleteAccountDialog from './atoms/dialogs/DeleteAccountDialog';
import { SectionTitle } from './atoms';

// navigator.registerProtocolHandler("geo", "geo:%s", "geopoint");

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "rgba(0,0,0,0.87)"
    },
    secondary: {
      main: "#ff0000"
    }
  }
})
// export const scroll = new SmoothScroll('a[href*="#"]', {
//   speed: 1000,
//   speedAsDuration: true,
//   easing: "Linear",
//   durationMin: 1000
// });

const initialState = {
  performingAction: false,
  signInDialog: {
    open: false,
  },
  signUpDialog: {
    open: false,
  },
  signOutDialog: {
    open: false,
  },
  settingsDialog: {
    open: false,
  },
  deleteAccountDialog: {
    open: false,
  },
  snackbar: {
    autoHideDuration: 0,
    message: "",
    open: false,
  }
}
const App = () => {

  const [state, setState] = useState(initialState);

  const { width } = useDimension();

  const fullScreen = width < 768;

  const { user, userData } = useUser();

  const onAdminClick = () => {
    alert("Coming soon...")
  };

  const _closeAllDialogs = () => {
    return {
      aboutDialog: {
        open: false,
      },

      signUpDialog: {
        open: false,
      },

      signInDialog: {
        open: false,
      },

      settingsDialog: {
        open: false,
      },

      deleteAccountDialog: {
        open: false,
      },

      signOutDialog: {
        open: false,
      },
    }
  };
  const closeAllDialogs = () => {
    setState({
      ...state,
      ..._closeAllDialogs()
    });
  };
  const signOut = () => {
    setState({ ...state, performingAction: true })
    authentication
      .signOut()
      .then(() => {
        setState({
          ...state,
          ..._closeAllDialogs(),
          ..._openSnackbar("Uscito")
        })
      })
      .catch((reason) => {
        const code = reason.code;
        const message = reason.message;

        switch (code) {
          default:
            openSnackbar(message);
            return;
        }
      })
  };
  const openDialog = (dialogId) => {
    const dialog = state[dialogId];
    if (!dialog || dialog.open === undefined || null) {
      return;
    }

    dialog.open = true;

    setState({ ...state, [dialogId]: dialog });
  };

  const deleteAccount = () => {
    setState({ ...state, performingAction: true });

    authentication
      .deleteAccount()
      .then((value) => {
        setState({
          ...state,
          ..._closeAllDialogs(),
          ..._openSnackbar("Account eliminato")
        });
      })
      .catch((reason) => {
        const code = reason.code;
        const message = reason.message;
        switch (code) {
          case 'auth/requires-recent-login':
            openSnackbar("Esci e accedi di nuovo al tuo utente per poterlo eliminare");
            return;
          default:
            openSnackbar(message);
            return;
        }
      })
    // .finally(() => {
    //   setState({
    //     ...state,
    //     performingAction: false,
    //   });
    // });
  };

  const closeDialog = (dialogId) => {
    const dialog = state[dialogId];

    if (!dialog || dialog.open === undefined || null) {
      return;
    }

    dialog.open = false;

    setState({ ...state, [dialogId]: dialog });
  };

  const openSnackbar = (message, autoHideDuration = 2) => {
    return setState({ ...state, ..._openSnackbar(message, autoHideDuration) })
  };

  const _openSnackbar = (message, autoHideDuration = 2) => {
    return {
      snackbar: {
        autoHideDuration: readingTime(message).time * autoHideDuration,
        message,
        open: true,
      },
    }
  };

  const closeSnackbar = (clearMessage = false) => {
    const { snackbar } = state;

    setState({
      ...state,
      snackbar: {
        message: clearMessage ? "" : snackbar.message,
        open: false,
      },
    });
  };

  // Men amour nasce per soddisfare ogni esigenza per la cura dell immagine maschile
  // Adottando metodiche tecniche artistiche e professionali , per arrivare ad un perfetto rapporto tra proporzione e bellezza
  // La qualita del risultato finale è piu importante di ogni altra cosa.
  // Piú di un idea, direi un invenzione.
  const description = `Men amour nasce per soddisfare ogni esigenza per la cura dell'immagine maschile
  \nAdottando metodiche tecniche artistiche e professionali, per arrivare ad un perfetto rapporto tra proporzione e bellezza
  \nLa qualità del risultato finale è piu importante di ogni altra cosa.
  \nPiú di un'idea, direi un'invenzione.`;
  const title = "";
  return (
    <MuiThemeProvider theme={theme} >
      <div>
        {/* Barra di navigazione */}
        <Navigation
          user={user}
          userData={userData}
          roles={userData?.roles || []}
          onSignInClick={() => openDialog("signInDialog")}
          onAdminClick={() => onAdminClick()}
          onSettingsClick={() => openDialog("settingsDialog")}
          onSignOutClick={() => openDialog("signOutDialog")}
        />
        {/* Header */}
        <Header />
        {/* Gallery */}
        <Gallery />
        {/* Text Section */}
        <div id='text-section' className='text-center'>
          <div className='container'>
            <SectionTitle title={title} description={description} />
          </div>
        </div>
        {/* Prices */}
        <Prices />
        {/* Collaboratori */}
        <Team />
        {/* Recensioni */}
        <Testimonials
          roles={userData?.roles || []}
          userData={userData}
          user={user}
        />
        {/* Contattaci e dove siamo */}
        <Contact />
        <SignUpDialog
          dialogProps={{
            fullScreen,
            open: state.signUpDialog.open,

            onClose: () => {
              closeAllDialogs();
            }
          }}
          performingAction={state.performingAction}
          // theme={theme}
          user={user}
          // userData={userData}
          openSnackbar={openSnackbar}
        // {...signUpDialog.props}
        />
        <SignInDialog
          onRegisterClick={() => openDialog("signUpDialog")}
          openSnackbar={openSnackbar}
          dialogProps={{
            fullScreen,
            open: state.signInDialog.open,
            onClose: () => {
              closeDialog("signInDialog");
            },
          }}
        // user={user}
        // userData={userData}
        // openSnackbar={openSnackbar}
        // {...signInDialog.props}
        />
        <DeleteAccountDialog
          dialogProps={{
            open: state.deleteAccountDialog.open,

            onClose: () => closeDialog("deleteAccountDialog"),
          }}
          deleteAccount={deleteAccount}
          performingAction={state.performingAction}
          // theme={theme}
          user={user}
          userData={userData}
          openSnackbar={openSnackbar}
        />
        {user && <SettingsDialog
          openSnackbar={openSnackbar}
          dialogProps={{
            fullScreen,
            open: state.settingsDialog.open,
            onClose: () => {
              closeDialog("settingsDialog");
            },
          }}
          onDeleteAccountClick={() => openDialog("deleteAccountDialog")}
          user={user}
          userData={userData}
        // openSnackbar={openSnackbar}
        // {...signInDialog.props}
        />
        }
        <AlertDialog
          dialogProps={{
            open: state.signOutDialog.open,

            onClose: () => closeDialog("signOutDialog"),
          }}
          performingAction={state.performingAction}
          // theme={theme}
          user={user}
          // userData={userData}
          openSnackbar={openSnackbar}
          title="Uscire dall'account?"
          contentText="Quando sei disconnesso non puoi gestire il tuo profilo e condurre altre attività che richiedono la tua registrazione."
          dismissiveAction={(
            <Button
              color="default"
              onClick={() => closeDialog("signOutDialog")}
            >
              Annulla
            </Button>
          )}
          confirmingAction={(
            <Button
              color="primary"
              className="button-bg"
              disabled={state.performingAction}
              variant="contained"
              onClick={signOut}
            >
              Esci
            </Button>
          )}
        // {...signOutDialog.props}
        />
        <Snackbar
          autoHideDuration={state.snackbar.autoHideDuration}
          message={state.snackbar.message}
          open={state.snackbar.open}
          onClose={closeSnackbar}
        />
      </div>
    </MuiThemeProvider>
  )
}

export default App
