import { useState } from 'react'
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { useDimension } from '../hooks';
import { Button, Typography, Dialog, DialogActions, DialogTitle, withStyles, Tooltip, IconButton } from '@material-ui/core';
import { Waze, Map as MapIcon } from 'mdi-material-ui';

const MAPS_LAT_INITIAL_CENTER = 41.932630991242014
const MAPS_LNG_INITIAL_CENTER = 12.653406833532458
const address = 'Via Tocco da Casauria, 90, 00131 Roma RM, Italia';
const telephone = '0679788482';

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },

  icon: {
    marginRight: theme.spacing(0.5),
  },

  divider: {
    margin: "auto",
  },

  grid: {
    marginBottom: theme.spacing(2),
  },
});

const _Contact = ({ google, classes }) => {

  const { width } = useDimension();

  const [mapWidth, setMapWidth] = useState(100);
  const [openDialog, setOpenDialog] = useState(false);
  
  const openWithWaze = () => {
    window.open(`https://waze.com/ul?ll=${MAPS_LAT_INITIAL_CENTER},${MAPS_LNG_INITIAL_CENTER}&navigate=yes`)
  };

  const openWithNativeMaps = () => {
    if /* if we're on iOS, open in Apple Maps */
      ((navigator.platform.indexOf("iPhone") !== -1) ||
      (navigator.platform.indexOf("iPad") !== -1) ||
      (navigator.platform.indexOf("iPod") !== -1))
      window.open(`maps://maps.google.com/maps?daddr=${MAPS_LAT_INITIAL_CENTER},${MAPS_LNG_INITIAL_CENTER}&amp;ll=`);
    else /* else use Google */
      window.open(`https://maps.google.com/maps?daddr=${MAPS_LAT_INITIAL_CENTER},${MAPS_LNG_INITIAL_CENTER}&amp;ll=`);
  };

  const handleAddressClick = (e) => {
    e.preventDefault();
    setOpenDialog(true);
  };
  
  const handleMarkClick = () => {
    setOpenDialog(true);
  };

  const calculateMapWidth = mapContainerRef => {
    const translatePX = (px) => Number(px.replace("px", "")) 
    const paddingLeft = translatePX(window.getComputedStyle(mapContainerRef).paddingLeft)
    const paddingRight = translatePX(window.getComputedStyle(mapContainerRef).paddingRight)
    return mapContainerRef.offsetWidth - paddingRight - paddingLeft;
  };
  
  return (
    <div id='contact'>
      <div className='container'>
        <div className="row">
          {/* <div className={`col-md-8 col-sm-12 ${width <= 768 ? "mr-4" : ""}`} ref={mapContainerRef => mapContainerRef && setMapWidth(calculateMapWidth(mapContainerRef))} style={{ height: 400 }}> */}
          <div className={`col-md-8 col-sm-12 ${width <= 768 ? "" : ""}`} ref={mapContainerRef => mapContainerRef && setMapWidth(calculateMapWidth(mapContainerRef))} style={{ height: 400 }}>
            <Map
              google={google}
              zoom={15}
              containerStyle={{ width: mapWidth, height: "100%" }}
              style={{ width: mapWidth, height: "100%" }}
              initialCenter={{ lat: MAPS_LAT_INITIAL_CENTER, lng: MAPS_LNG_INITIAL_CENTER }}
            >
              <Marker
                onClick={handleMarkClick}
                position={{ lat: MAPS_LAT_INITIAL_CENTER, lng: MAPS_LNG_INITIAL_CENTER }}
              />
            </Map>
          </div>
          <div className='col-md-3 col-md-offset-1 col-sm-12 contact-info'>
            <div className='contact-item'>
              <h3>Trovaci e contattaci</h3>
              <a href={`#page-top`} onClick={handleAddressClick}>
                <span>
                  <i className='fa fa-map-marker'></i> Indirizzo
                </span>
                {address}
              </a>
            </div>
            <div className='contact-item'>
              <a href={`tel:${telephone}`}>
                <p>
                  <span>
                    <i className='fa fa-phone'></i> Telefono
                </span>{' '}
                  {telephone}
                </p>
              </a>
            </div>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-md-12'>
            <div className='social'>
              <ul>
                <li>
                  <a target="_blank" rel="noreferrer" href={'https://www.facebook.com/mattianardonibarber'}>
                    <i className='fa fa-facebook'></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href={'https://www.instagram.com/_men_amour_/'}>
                    <i className='fa fa-instagram'></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openDialog}
      // onKeyPress={this.handleKeyPress}
      // onExited={this.handleExited}
      >
        <DialogTitle disableTypography>
          <Typography variant="h6">Apri con</Typography>
          <Tooltip title="Chiudi">
            <IconButton
              className={classes.closeButton}

              onClick={() => setOpenDialog(false)}
            >
              <i className="fa fa-times"></i>
              {/* <CloseIcon /> */}
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogActions>
          <Button
            // className="button-bg"
            className="waze-button"
            variant="contained"
            onClick={openWithWaze}
          >
            {'Waze '}<Waze />
          </Button>
          <Button
            // className="button-bg"
            className="maps-button"
            variant="contained"
            onClick={openWithNativeMaps}
          >
            {'Mappe '}<MapIcon />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export const Contact = GoogleApiWrapper({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY
})(withStyles(styles)(_Contact));