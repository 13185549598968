import { useEffect, useReducer } from "react";
// import { PhotoGallery, SectionTitle } from "../atoms";
import { SectionTitle } from "../atoms";
import { storage } from "../firebase";
import { useDimension } from "../hooks";
import { Carousel } from 'react-bootstrap';

function listToMatrix(list, elementsPerSubArray) {
  var matrix = [], i, k;

  for (i = 0, k = -1; i < list.length; i++) {
    if (i % elementsPerSubArray === 0) {
      k++;
      matrix[k] = [];
    }

    matrix[k].push(list[i]);
  }

  return matrix;
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'set_photos':
      return { loading: false, photos: action.payload };
    case 'set_loading':
      return { ...state, loading: true };
    default:
      return state;
  };
};

export const Gallery = () => {

  const [state, dispatch] = useReducer(reducer, { loading: true, photos: [] });
  const { loading, photos } = state;
  const { width } = useDimension();

  useEffect(() => {
    dispatch({ type: 'set_loading' });
    const fetchImages = async () => {

      let result = await storage.ref().child('gallery').listAll();
      let urlPromises = result.items.map(imageRef => imageRef.getDownloadURL());

      return Promise.all(urlPromises);
    };

    const loadImages = async () => {
      const urls = await fetchImages();
      dispatch({ type: 'set_photos', payload: urls })
    };
    loadImages();
  }, []);

  if (width <= 450) {
    return (
      <div id='portfolio' className='text-center'>
        <div className='container'>
          <SectionTitle title="Gallery" />
          <div className='row'>
            <div className='container-fluid' >
              <Carousel indicators={false} interval={null} slide={false} fade={false}>
                {!loading && photos.map(photo => (
                  <Carousel.Item key={photo} >
                    <div style={{ height: "300px", alignItems: "center", justifyContent: "center", display: "flex" }}>
                      <img
                        className="d-block img-fluid"
                        src={photo} alt={photo} />
                    </div>
                  </Carousel.Item>
                )
                )}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <SectionTitle title="Gallery" description="" />
        {/* <SectionTitle title="Gallery" description="Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
        dapibus leonec." /> */}
        <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
          {!loading && listToMatrix(photos, 6).map((photosGroup, index) => (
            <div key={`photo-${index}`} style={{ flex: "33%", maxWidth: "33%", padding: "0 4px" }}>
              {photosGroup.map(photo => <img key={photo} src={photo} alt={photo} style={{ marginTop: 8, verticalAlign: "middle", width: "100%" }} />)}
            </div>
          )
          )}
        </div>
        {/* <PhotoGallery url={photo} altText={photo} key={photo} /> */}
      </div>
    </div>
  )
}
