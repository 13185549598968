import { useEffect, useState } from 'react';
import { firestore } from "../firebase";
// import authentication from '../services/authentication';

const DEFAULT_AUTHOR = "Anonimo";
const initialNewReview = { rating: 0, comment: "", author: DEFAULT_AUTHOR };

const useReviews = (user) => {

    const [reviews, setReviews] = useState([]);
    const [newReview, setNewReview] = useState(initialNewReview);

    useEffect(() => {
        const listener = firestore.collection('reviews').onSnapshot((reviewsCollection) => {
            const _reviews = [];
            let i = 0;
            for (let review of reviewsCollection.docs) {
                if (i >= 5) break;
                if (review.data().rating < 3) continue;
                _reviews.push({ ...review.data(), id: review.id });
                i++;
            }
            setReviews(_reviews);
        });
        return listener;
    }, []);

    useEffect(() => {
        setNewReview({...initialNewReview, author: (user && user.username) ? user.username : DEFAULT_AUTHOR});
    }, [user]);

    const handleSave = () => {
        firestore.collection('reviews').add(newReview);
        setNewReview({...initialNewReview, author: (user && user.username) ? user.username : DEFAULT_AUTHOR});
    };

    const handleRemove = (id, roles) => {
        if (roles.includes("admin")) {
            firestore.collection('reviews').doc(id).delete();
        }
    };
    return { reviews, handleSave, newReview, setNewReview, handleRemove };
};

export { useReviews };