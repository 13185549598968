export const Header = () => {
  return (
    <header id='header'>
      <img
        src={process.env.PUBLIC_URL + "/img/menamour_logo.jpg"}
        style={{ paddingTop: 30, width: "100%", height: "auto" }}
        alt="logo"
      />
    </header>
  )
};