import { Navbar, Nav } from 'react-bootstrap';
import { Button, Avatar, useMediaQuery } from '@material-ui/core';
import { Menu, MenuItem, Divider } from '@material-ui/core';
import { useState } from 'react';
import authentication from '../services/authentication';

export const Navigation = ({
  user,
  userData,
  roles,
  onSettingsClick,
  onSignInClick,
  onSignOutClick,
  onAdminClick
}) => {
  const getDisplayInitial = (fields) => authentication.getNameInitials(fields);
  const mediaQuery = useMediaQuery("(min-width:768px)");
  const [menu, setMenu] = useState(null);
  
  return (
    <Navbar expand="md" variant="dark" className="navbar-header-bg" fixed="top" collapseOnSelect>
      <Navbar.Brand style={{ fontFamily: "Times New Roman", letterSpacing: 2, fontSize: "1.8rem", flex: 1, textAlign: "left", color: "white" }} href='#header'>MEN AMOUR</Navbar.Brand>
      <Navbar.Toggle style={{ color: "white" }} aria-controls="navbarScroll" />

      <Navbar.Collapse id="menu">
        <Nav navbarScroll className="mr-auto my-2 my-lg-0" style={{ flexWrap: mediaQuery && "wrap", maxHeight: 300 }} >
          <Nav.Link href="#portfolio"><Button className="nav-button">GALLERY</Button></Nav.Link>
          <Nav.Link href="#prices"><Button className="nav-button">LISTINO PREZZI</Button></Nav.Link>
          <Nav.Link href="#products"><Button className="nav-button">PRODOTTI</Button></Nav.Link>
          <Nav.Link href="#team"><Button className="nav-button">IL NOSTRO TEAM</Button></Nav.Link>
          <Nav.Link href="#testimonials"><Button className="nav-button">RECENSIONI</Button></Nav.Link>
          <Nav.Link href="#contact"><Button className="nav-button">CONTATTACI</Button></Nav.Link>
          {!mediaQuery && user && <>
            <Nav.Link><Button className="nav-button" onClick={onSettingsClick}>Impostazioni</Button></Nav.Link>
            {roles.includes("admin") && <Nav.Link><Button className="nav-button" onClick={onAdminClick}>Console Amministrazione</Button></Nav.Link>}
          </>}
          <Nav.Item style={{ width: mediaQuery && "52px", height: mediaQuery && "52px" }}>
            <Button style={{ borderRadius: mediaQuery && "50%", width: mediaQuery && "52px", height: mediaQuery && "52px" }} className="nav-button" onClick={(e) => { user ? (mediaQuery ? setMenu(e.currentTarget) : onSignOutClick()) : onSignInClick() }}>
              {user ?
                <Avatar src={user.photoURL} alt={'...'} style={{ justifyContent: !mediaQuery && "flex-start", color: "#000", backgroundColor: !mediaQuery && "transparent" }}>
                  {getDisplayInitial({ ...user, ...userData })}
                </Avatar>
                : <Avatar style={{ justifyContent: !mediaQuery && "flex-start", color: "#000", backgroundColor: !mediaQuery && "transparent" }}>
                  <i className='fa fa-user'></i>
                </Avatar>

              }
              {!mediaQuery && <span style={{ marginLeft: 5 }}>
                {user ? "Esci" : "Accedi"}
              </span>
              }
            </Button>
          </Nav.Item>
          {mediaQuery &&
            <Menu
              disableScrollLock
              disableAutoFocus={true}
              anchorEl={menu}
              open={Boolean(menu)}
              onClose={() => setMenu(null)}
            >
              {roles.includes("admin") && <MenuItem onClick={() => {
                setMenu(null);
                onAdminClick();
              }}>
                Console Amministrazione
            </MenuItem>
              }
              <MenuItem onClick={() => {
                setMenu(null);
                onSettingsClick();
              }}>
                Impostazioni
            </MenuItem>
              <Divider />
              <MenuItem onClick={() => {
                setMenu(null);
                onSignOutClick();
              }}>
                Esci
            </MenuItem>
            </Menu>
          }
        </Nav>
      </Navbar.Collapse>
    </Navbar >
  )
}
