import React from 'react';
import { SectionTitle } from '../atoms';
import { usePrices, useProducts } from '../hooks';
import { Avatar } from '@material-ui/core';

export const Prices = () => {

    const [prices] = usePrices();
    const [products] = useProducts();

    return (
        <div id='prices'className='text-center'>
            <div className='container'>
                <SectionTitle title="Listino Prezzi" />
                <div className='row'>
                    {prices.map((price, i) => (
                        <div key={`${price.name}-${i}`} className={`col-md-4 m${i===0? "b":"y"}-1`}>
                            {' '}
                            {/* <i className={price.icon}></i> */}
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}} className='service-desc'>
                                <Avatar style={{ height: 60, width: 60}} alt={price.photoUrl} src={price.photoUrl} />
                                <h3>{price.name}</h3>
                                <p style={{ marginBottom: "0.5rem" }}>{price.price} €</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='container mt-4' id='products'>
                <SectionTitle title="Prodotti" />
                <div className='row'>
                    {products.map((products, i) => (
                        <div key={`${products.name}-${i}`} className={`col-md-4 m${i === 0? "b" : "y"}-1`}>
                            {' '}
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}} className='service-desc'>
                                <Avatar style={{ height: 60, width: 60}} alt={products.photoUrl} src={products.photoUrl} />
                                <h3>{products.name}</h3>
                                <p style={{ marginBottom: "0.5rem" }}>{products.price} €</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
